export const _testMode = false;
export const _debugMode = false;
export const _appVersion = 3.5;
export const _rootName = "root";
export const _libName = "{{lib}}";
export const _storageVersion = 1;

let defaultLanguage:string = navigator.language.split('-')[0];
if(defaultLanguage !== 'en' && defaultLanguage !== 'it' && defaultLanguage !== 'de'){
	defaultLanguage = "en";
}


export const _lang = defaultLanguage;
export const _parentMode = false;
export const _host = "https://dsapp.app";
export const _pathSeparatorPlaceholder = "j0T"; // do NOT use chars that need to be encoded in URL!

export const _SERVER_URL_IMAGES_1="https://raw.githubusercontent.com/twrblog/dsapp-media/main";
export const _SERVER_URL_RP="http://192.168.1.8:5001"

// REF-A
export const _THREE_DOTS = "TD3dS";
export const _ACTIVITY_ROOT = "AT1X";
export const _TEMPLATE_A = "TXP1";
export const _ANY_ACTIVITY_PREFIX = "ACNr";
export const _NEW_LINE ="1nL";
export const _ALIAS_SUFFIX = "Z~";
export const _CARD_EMPTY="1nV1s1bL3!";
export const _SPACE_CARD="Sp4c3C4!rd";

export const _TEMPLATE_A_ACTIVITIES_NR = 20;
 
// REF-A 
export const charsMappingFromURL = (title:string, lang?:string) => {
    let result = title;

    if(result.startsWith(_ANY_ACTIVITY_PREFIX)){
        return ""
    }
    
    result = result.replaceAll(_pathSeparatorPlaceholder, _pathSeparator);
    // Replace all consecutive _ALIAS_SUFFIX at end of the title with empty (=remove them)

    //while(result.endsWith(_ALIAS_SUFFIX)){
    //    result = result.substring(0, result.length - _ALIAS_SUFFIX.length);
    //}

    // Substring from beginning to the first occurence of _ALIAS_SUFFIX
    if(result.includes(_ALIAS_SUFFIX)){
        result = result.substring(0, result.indexOf(_ALIAS_SUFFIX));
    }

    
    switch(result){
        case _THREE_DOTS:
            return "...";
        case _ACTIVITY_ROOT:
            return "";
        case _NEW_LINE:
            return "";
        case _TEMPLATE_A:
            return _dict.visualComprehensionTask[lang || _lang];
        case _pathSeparatorPlaceholder:
            return _pathSeparator;
        case _CARD_EMPTY:
            return "";
        case _SPACE_CARD:
            return "";
        default:
            return result;
            
    }
}

export const charsMappingToURL = (label:string) => {
    let result = label;
    result = result.replaceAll(_pathSeparator, _pathSeparatorPlaceholder);
    
    return result;
}

export const shouldIReadThis = (label: string) => {
    let result = label.replace(/[^a-zA-Z0-9]/g, '');

    return result.length > 0;
}

//export const _host = "http://localhost:8100";


export const _BULK_IMPORT_CARD_LIMIT = 5; // The amount of cards to fetch for images during
// bulk import. This is to prevent the app from fetching too many images

//REF-1 (set as initial value which is different than any of the real ones, i.e. {}, null if possible, otherwise useEffect structures in App.tsx could not work as expected)
export const _nodes = {"root" : []};

export const _l2audio = {}; // label to card audio
export const _l2image = {}; // label to card image
export const _l2visibility = {}; // label to card visibility
export const _l2color = {}; 
export const _possibleCardColors = ['white', 'springgreen', 'lightsalmon','lightblue','moccasin', 'lavender'];
export const _defaultCardSize = "auto";//"smallCards";
export const _homeNodePath = _rootName;
export const _bulkImportPage = "/page/bulkImport";

//export const _welcomePage = "/page/quiz";
export const _pathSeparator = ".";
export const _emptyLabel = "#-1*lkk_15"; // label to ignore
export const _minCardsGridCols = 3;
export const _photoPrefix = "pic_";
export const _soundPrefix = "sound_";
export const _cardVisibilityPrefix = "visibility_";
export const _freeCards = 30;
export const _securityKey = "98jH121/**`?!-äöö!!8585asd/£%$%£asd..!asdmasda2olfdxpà6780"; 
export const _stripeLink = "https://buy.stripe.com/14kdTc2O6fac8Q8288"; // 5.90
export const _stripeCustomerRefVar = "client_reference_id";
export const remoteSharedLocation = "shared/cachedLocalAutoL2image";
export const _daysAgoStripeEventSearch = 7;
export const _subscriptionValidUntill = 0;
export const _synched = false;
export const _preferencesKeyPrefix = "dsapp_"
export const _languages = ["it", "en", "de"]
export const _userUniqueId = null;
export const _nextSubscriptionDueDate = null;
export const _userEmail = null;
export const _syncTimer = 45*60*1000; // every 45 minutes
export const _updateAppTimer = 1000*60*60*5 // every 5 hours 
export const _maxSyncCallsPerMinute = 1;
export const _maxPlaySoundCallsPer5Seconds = 5;
export const _showCardSizing = false;
export const _maxSentenceLen = 10;
export const _topSentences = [];
export const _cachedLocalAutoL2image = {}; 
export const _clipboardCards = [];
export const _previousPath = "";
export const _SMALL_SCREEN_WIDTH = 500;
export const _LIMIT_CHARS_URL_SHARE = 100*5;
export const _defaultButtonColor = "#007ea7";
export const _defaultButtonColorLighter = "#CCE0E4";
export const _defaultPurpleButtonColor = "#800080";
export const _maxAIInput = 35; // chars number
export const _minAIInput = 5; // chars number
export const _cardFontSizeMin = 10;
export const _cardFontSizeMax = 30;
export const _cardWidthMin = 100;
export const _cardWidthMax = 500;
export const _cardSizeMultiplierMin = 0.5;
export const _cardSizeMultiplier = 1;
export const _cardSizeMultiplierMax = 20;
export const _cardSizeDelta = 0.1;
export const _defaultParams = {
    fontSize: 16, cardWidth: 120, invertImgTxt: false, capitalizeText: 1, isRandomFlag: false,
    nrCols: null
}
export const _AIAssistantFunctionURL = "https://europe-west1-dsapp-31794.cloudfunctions.net/smartAssistant"; //"http://127.0.0.1:5001/dsapp-31794/europe-west1/AIAssistant/explain";
//export const _checkSubFunctionURL = "http://127.0.0.1:5001/dsapp-31794/europe-west1/checkSubscription";
export const firebaseConfig = {
    apiKey: "AIzaSyANl_RcUOSqjWSViwVDL_mmLCDA_gU3t3g",
    authDomain: "dsapp.app", //"dsapp-31794.firebaseapp.com",
    projectId: "dsapp-31794",
    storageBucket: "dsapp-31794.appspot.com",
    messagingSenderId: "565317169322",
    appId: "1:565317169322:web:55ffed04ffd284b30320d3",
    measurementId: "G-9Y829T4932"
};

export const _feedbackMinLen = 10;

  /* Here words are mapped to other words to improve the arasaac image search relevance! */
export const _fixedMappingDict = {"sono":"io"};

export const _dict: { [id:string] : { [id:string] : string }} = {
    "create": {"it": "Crea!", "en":"Create!", "de": "Erstellen!"},
    "talk":{"it": "Parla", "en":"Talk", "de": "Sprechen"}, //REF-3
    "send": {"it": "Invia", "en": "Send", "de": "Senden"},
    "yesThanks": {"it": "Si, grazie", "en": "Yes, thanks", "de": "Ja, danke"},
    "thanksForYourContribution": {"it": "Grazie per il tuo contributo!", "en": "Thanks for your contribution!", "de": "Danke für Ihren Beitrag!"},
    "quiz":{"it": "Quiz", "en":"Quiz", "de": "Quiz"},
    "play":{"it": "Gioca", "en":"Play", "de":"Spielen"}, 
    "parentMode":{"it": "Mod. Admin", "en":"Admin Mode", "de":"Adminmodus"},
    "code": {"it": "Codice di sicurezza", "en": "Security code", "de": "Sichereitscode"},
    "defaultLabel" : {"it": "Testo carta/e (1 riga/carta)", "en":"Card(s) text (1 line/card)", "de":"Kartentext (1 Zeile/Karte)"},
    "defaultLabelEdit" : {"it": "Aggiungi o riordina le carte", "en":"Add or rearrange cards", "de":"Karten hinzufügen oder neu anordnen"},
    "enterPswMsg" : {"it": "Nuovo codice di sicurezza", "en":"New security code", "de": "Neuer Sicherheitscode"},
    "dontWorryYouCanEasilyChangeItLater": {"it": "Non preoccuparti, puoi cambiarlo facilmente dopo ;)", "en":"Don't worry, you can easily change it later ;)", "de":"Keine Sorge, Sie können es später leicht ändern ;)"},
    "wrongPswMsg" : {"it": "Codice errato!", "en":"Wrong code!", "de":"Falscher Code!"},
    "dismissToast" : {"it": "Chiudi", "en":"Close", "de":"Schließen"},
    "reqNewPassword" : {"it": "Cambia codice", "en":"Change code", "de":"Code ändern"},
    "cards": {"it": "Carte", "en": "Cards", "de":"Karten"},
    "remainingCards" : {"it": "Rimanenti", "en":"Remaining", "de":"Verbleibend"},
    "freeQuotaHeader" : {"it": "Limite carte superato!", "en":"Card limit exceeded!", "de": "Kartenlimit überschritten!"},
    "freeQuotaMsg" : {"it" : "Hai superato il limite di carte consentito. Diventa utente premium per poter aggiungere un numero illimitato di carte. Se lo sei già, apri il menu e clicca sul pulsante 'Aggiorna' per sincronizzare i tuoi dati.",
                      "en" : "You have exceeded the card limit. Become a premium user to add an unlimited cards. If you already are, open the menu and click on the 'Update' button to synchronize your data.",
                      "de" : "Sie haben das Kartenlimit überschritten. Werden Sie ein Premium-Benutzer, um eine unbegrenzte Anzahl von Karten hinzufügen zu können. Wenn Sie bereits sind, öffnen Sie das Menü und klicken Sie auf die Schaltfläche 'Aktualisieren', um Ihre Daten zu synchronisieren."},
    "freeQuotaPremiumBtnText": {"it": "Diventa Utente Premium", "en":"Become a Premium User", "de":"Werden Sie Premium-Benutzer"},
    "syncMsg" : {"it": "In caso di conflitto, preferisci mantenere i dati locali o quelli remoti?",
                 "en": "In case of conflicts, do you prefer to keep local or remote data?",
                 "de": "Ziehen Sie es bei Konflikten vor, lokale oder remote Daten zu behalten?"},
    "keepLocal": {"it": "Locali", "en":"Local", "de": "Lokale"},
    "keepRemote": {"it": "Remoti", "en":"Remote", "de": "Remote"},
    "lang" : {"it": "Italiano", "en": "English", "de": "Deutsch"},
    "smartAssistant": {"it": "Assistente Smart", "en": "Smart Assistant", "de": "Smart Assistant"},
    "chooseTopic": {"it": "Quale argomento vuoi trattare?", "en": "Which topic do you want to talk about?", "de": "Über welches Thema möchten Sie sprechen?"},
    "syncDone" : {"it": "Sincronizzazione effettuata!", "en":"Synchronization done!", "de":"Synchronisierung erledigt!"},
    "syncProblem" : {"it": "Sincronizzazione non riuscita. Riprovare più tardi.", "en":"Synchronization unsuccessful. Please try again later.",
    "de":"Synchronisierung fehlgeschlagen. Bitte versuchen Sie es später erneut."},
    "syncPremiumMsg" : {"it": "La sincronizzazione cloud permette il futuro recupero delle carte. Diventa utente premium per attivarla.",
                        "en": "Cloud synchronization allows the future recovery of cards. Become premium user to activate it.",
                        "de": "Die Cloud-Synchronisierung ermöglicht die zukünftige Wiederherstellung von Karten. Werden Sie Premium -Benutzer, um es zu aktivieren."},
    "syncPremiumHeader" : {"it": "Funzionalità premium", "en":"Premium feature", "de":"Premium-Funktion"},
    "chooseSyncOpt": {"it": "Scegli un'opzione di sincronizzazione", "en":"Choose a sync option", "de":"Wählen Sie eine Synchronisierungsoption"},
    "contacts" : {"it": "https://docs.google.com/forms/d/e/1FAIpQLSd1IiWXYogptbi9nFt3FvdpWSulSOO3vnmJxR01dMeY2CivFQ/viewform?usp=sf_link",
                  "en": "https://docs.google.com/forms/d/e/1FAIpQLSfVUU28Ev2U1tR63-Up9buOSdb3eKx0x4-X-NfFf9lNYlQD4Q/viewform?usp=sf_link",
                  "de": "https://docs.google.com/forms/d/e/1FAIpQLSefMXOS2PZVYZQBjs_Ap5zpJRtPb9q26OYsP2N4AGQwJUYVTg/viewform?usp=sf_link"},
    "mergeData": {"it":"Unisci dati locali e remoti", "en":"Merge local and remote data", "de":"Lokale und Remote-Daten zusammenführen"},
    "overwriteRemote": {"it": "Sovrascrivi dati remoti", "en":"Overwrite remote data", "de":"Remote-Daten überschreiben"},
    "overwriteLocal":{"it":"Sovrascrivi dati locali","en":"Overwrite local data", "de":"Lokale Daten überschreiben"},
    "confirmDelRemote":{"it": "Confermi di voler sostituire tutti i dati REMOTI? Attenzione, questa scelta non è reversibile.",
                        "en": "Do you really want to replace all REMOTE data?",
                        "de": "Möchten Sie wirklich alle REMOTE-Daten ersetzen?"},
    "confirmDelLocal":{"it": "Confermi di voler sostituire tutti i dati LOCALI? Attenzione, questa scelta non è reversibile",
                       "en": "Are you sure you want to replace all LOCAL data?",
                       "de": "Möchten Sie wirklich alle LOKALEN Daten ersetzen?"},
    "contactMsg" : {"it": "Contattaci",
                    "en": "Contact us",
                    "de" : "Kontakte"},
    "login" : {"it": "Accedi", "en": "Login", "de":"Einloggen"},
    "loginWithGoogle": {"it": "Accedi con Google", "en": "Login with Google", "de":"Mit Google einloggen"},
    "logout": {"it": "Esci", "en":"Logout", "de":"Ausloggen"},
    "zeroScreen": {"it":"Per iniziare, apri il menu a sinistra e attiva la modalità admin.",
                   "en":"To begin, open the menu on the left, switch the admin mode.",
                  "de":"Zum starten, öffnen Sie das linke Menü, aktivieren Sie den Adminmodus."},
    "zeroScreen2": {"it": "Non preoccuparti se non ricordi (o non hai già impostato) il codice di sicurezza. Puoi sempre cambiarlo dopo ogni inserimento errato.",
                    "en": "Don't worry if you don't remember (or you have not set yet) the security code. You will be able to change it after each wrong insertion.",
                    "de": "Keine Sorge, falls Sie den Sicherheitscode vergessen (oder nicht eingestellt) haben, da Sie immer nach jeder falschen Eingabe die Möglichkeit haben, diesen neu einstellen."},
    "oneScreen":{"it":"Clicca sul pulsante in basso per aggiungere nuove carte.",
                 "en": "Click on the below button to add new cards.",
                "de": "Clicken Sie auf den unteren Knopf, um neue Karte hinzuzufügen."},
    "sync" : {"it": "Sincronizza", "en": "Sync", "de": "Sync"},
    "syncBack" : {"it": "Annulla", "en": "Back", "de": "Zurück"},
    "back": {"it": "Indietro", "en": "Back", "de": "Zurück"},
    "undo": {"it": "Annulla", "en": "Undo", "de": "Rückgängig"},
    "yes": {"it":"Si","en":"Yes","de":"Ja"},
    "no": {"it":"No","en":"No","de":"Nein"},
    "reset": {"it":"Reset","en":"Reset","de":"Reset"},
    "settingsAddCardMsg": {"it": "Posizione nuova carta" , "en": "New card position", "de": "Neue Karte"},
    "settingsAddCardOpt1": {"it": "In testa", "en": "To the head", "de":"Am Anfang"},
    "settingsAddCardOpt2": {"it": "In coda", "en": "To the tail", "de":"Am Ende"},
    "settings": {"it": "Impostazioni", "en":"Settings", "de":"Einstellungen"},
    "newCard":{"it":"Nuova/e carta/e", "en":"New card(s)", "de":"Neue Karte(n)"},
    "editCards":{"it":"Ramo corrente", "en":"Current branch", "de":"Aktueller Abschnitt"},
    "edit": {"it":"Modifica", "en":"Edit", "de":"Bearbeiten"},
    "ok":{"it":"Ok", "en":"Ok", "de":"Ok"},
    "language":{"it":"Lingua","en":"Language","de":"Sprache"},
    "showPathOptions":{"it":"Opzioni percorso", "en":"Path options","de":"Pfadoptionen"},
    "showSentence":{"it":"Mostra frase laterale", "en":"Show Sentence","de":"Satz anzeigen"},
    "loadSentences": {"it":"Carica", "en":"Load", "de":"Hochladen"},
    "importSentences": {"it":"Pronti all'uso", "en":"Ready to use", "de":"Fertig zum Gebrauch"},
    "insertSentences": {"it":"Inserisci una frase per riga...", "en":"Enter one sentence per line...", "de":"Geben Sie einen Satz pro Zeile ein..."},
    "overwriteExistingCards": {"it": "Sovrascrivi le carte già esistenti", "en": "Overwrite existing cards", "de":"Vorhandene Karten überschreiben"},
    "joinToExistingCards": {"it": "Unisci alle carte già esistenti", "en": "Join to existing cards", "de":"Mit vorhandenen Karten zusammenführen"},
    "bulkImportOption": {"it": "Generatore di carte", "en":"Cards generator", "de": "Kartengenerator"},
    "images" : {"it": "immagini", "en":"images", "de":"Bilder"},
    "sounds" : {"it": "audio", "en":"audio", "de":"audio"},
    "colors" : {"it": "colori", "en":"colors", "de":"Farben"},
    "changeColor": {"it": "Cambia colore", "en":"Change color", "de":"Farbe ändern"},
    "clickToGoIntoThisCardAndCreateANewBranch": {"it": "Clicca qui per entrare in questa carta...", "en":"Click here to go into this card...", "de":"Klicken Sie hier, um in diese Karte zu gehen..."},
    "visibility": {"it": "visibilità", "en":"visibility", "de":"Sichtbarkeit"},
    "questionType": {"it": "Tipologia", "en":"Type", "de":"Typ"},
    "question": {"it": "Domanda", "en":"Question", "de":"Frage"},
    "givenAnswer": {"it": "Risposta data", "en":"Given answer", "de":"Gegebene Antwort"},
    "otherAnswers": {"it": "Altre risposte", "en":"Other answers", "de":"Andere Antwort"},
    "isRight": {"it": "Corretta?", "en":"Is Right?", "de":"Ist richtig?"},
    "timestamp": {"it": "Quando", "en":"When", "de":"Wann"},
    "text": {"it": "Testo", "en":"Text", "de":"Text"},
    "image": {"it": "Immagine", "en":"Image", "de":"Bild"},
    "rightAns": {"it": "Corrette", "en":"Right", "de":"Richtig"},
    "wrongAns": {"it": "Errate", "en":"Wrong", "de":"Falsch"},
    "startDate": {"it": "Data inizio", "en":"Start date", "de":"Startdatum"},
    "endDate": {"it": "Data fine", "en":"End date", "de":"Enddatum"},
    "minute": {"it": "Minuto", "en":"Minute", "de":"Minute"},
    "hour": {"it": "Ora", "en":"Hour", "de":"Stunde"},
    "day": {"it": "Giorno", "en":"Day", "de":"Tag"},
    "week": {"it": "Settimana", "en":"Week", "de":"Woche"},
    "month": {"it": "Mese", "en":"Month", "de":"Monat"},
    "downloadTableAsCSV": {"it": "Scarica tabella come file CSV", "en":"Download table as CSV file", "de":"Tabelle als CSV Datei herunterladen"},
    "downloadTableAsXLSX": {"it": "Scarica tabella per Excel", "en":"Download table for Excel", "de":"Tabelle für Excel herunterladen"},
    "currentTimeframe": {"it": "Unità di tempo", "en": "Time unit", "de": "Zeiteinheit"},
    "resetCollectedData": {"it": "Resetta dati raccolti","en": "Reset collected data", "de":"Gesammelte Daten zurücksetzen"},
    "noDataToDisplay": {"it":"Non ci sono ancora dati da visualizzare. Gioca al quiz con la modalità admin disattivata per raccogliere dati.",
                        "en": "There is no data to display, yet. Please play the quiz with admin mode off to gather data.",
                        "de": "Es sind noch keine Daten zum Anzeigen vorhanden. Bitte spielen Sie das Quiz mit ausgeschaltetem Adminmodus, um Daten zu sammeln."
                    },
    "chartOfAnswers": {"it":"Grafico delle risposte corrette ed errate nell'unità di tempo specificata",
                       "en":"Chart of right and wrong answers over specified time unit",
                       "de":"Diagramm der richtigen und falschen Antworten über eine bestimmte Zeiteinheit"},
                       "tableOfAnswers": {"it": "Tabella delle risposte date",
                       "en": "Table of given answers",
                       "de": "Tabelle der gegebenen Antworten"},
    "selectCardsColor": {"it": "Seleziona colore carta/e", "en":"Select card(s) color", "de":"Wählen Sie die Farbe der Karte(n) aus"},
    "nrAnswersQuiz": {"it": "Numero di scelte al quiz", "en":"Number of choices for quiz", "de":"Anzahl der Auswahlmöglichkeiten für das Quiz"},
    "resetTopSentences": {"it": "Resetta storico frasi frequenti","en":"Reset frequent sentences history","de":"Setzen Sie den Verlauf häufiger Phrasen zurück"},
    "notice": {"it": "Attenzione", "en":"Notice","de":"Hinweis"},
    "noticeMsg": {"it": "Come utente base hai accesso ad un massimo di "+_freeCards+" carte. Questo potrebbe comportare la limitazione di alcune funzionalità (es. copia-incolla, condivisione, inserimento)",
                    "en": "As a basic user you have access to a maximum of "+_freeCards+" cards. This could result in the limitation of some features (e.g. copy-paste, sharing, insertion)",
                    "de": "Als Basisbenutzer haben Sie Zugriff auf maximal "+_freeCards+" Karten. Dies könnte zu Einschränkungen einiger Funktionen führen (z.B. Kopieren und Einfügen, Freigeben, Einfügen)"},
    
    "saveListAs": {"it": "Salva lista come...", "en":"Save list as...","de":"Liste speichern unter..."},
    "pathCopied" : {"it": "Carte copiate negli appunti !", "en":"Cards copied to clipboard !", "de":"Karten in die Zwischenablage kopiert !"},
    "pasteHere" : {"it":"Incolla (Opzioni)", "en":"Paste Options", "de":"Einfügeoptionen"},
    "areYouSureToPasteHere" : {"it":"Sei sicuro di incollare qui le carte precedentemente copiate?", "en":"Are you sure to paste the previously copied cards here?", "de":"Sind Sie sicher, dass Sie die zuvor kopierten Karten hier einfügen?"},
    "record": {"it":"Registra","en":"Record","de":"Aufzeichnen"},
    "stop": {"it":"Stop","en":"Stop","de":"Stop"},
    "delete": {"it":"Eliminare","en":"Delete","de":"Entfernen"},
    "deleteAll": {"it":"Elimina carta e media associati", "en":"Delete card and associated media", "de":"Karte und zugehörige Medien löschen"},
    "deleteNewLine": {"it": "Elimina ritorno a capo", "en":"Delete new line", "de":"Neue Zeile löschen"},
    "removeCell": {"it":"Rimuovi cella (Opzioni)", "en":"Remove cell (Options)", "de":"Zelle entfernen (Optionen)"},
    "removeEntireCell": {"it":"Rimuovi intera cella (sposta le successive verso sinistra)", "en":"Remove entire cell (shift the following ones to the left)", "de":"Entire Zelle entfernen (verschieben Sie die folgenden nach links)"},
    "emptyCell": {"it":"Svuota cella", "en":"Empty cell", "de":"Zelle leeren"},
    "imagesOnly": {"it":"Solo immagini", "en":"Images only", "de":"Nur Bilder"},
    "recordedAudio": {"it":"Solo audio registrato", "en":"Recorded audio only", "de":"Nur aufgezeichnetes Audio"},
    "card": {"it": "Carta", "en": "Cards", "de":"Karte"},
    "clean": {"it": "Aggiorna", "en":"Update", "de":"Aktualisieren"},
    "loading": {"it": "Caricamento in corso...", "en":"Loading...", "de":"Bitte warten..."},
    "confirm": {"it": "Conferma", "en":"Confirm", "de":"Bestätigen"},
    "searchImages": {"it": "Cerca immagini", "en":"Search for images", "de":"Bildersuche"},
    "searchMoreImages": {"it": "Cerca altre immagini", "en":"Search for more images", "de":"Suche nach mehr Bildern"},
    "thisDevice": {"it": "Dispositivo", "en":"Device", "de":"Gerät"},
    "loadContentFrom": {"it": "Carica contenuto da", "en":"Load content from", "de":"Inhalt laden von"},
    "dismiss":{"it":"Chiudi", "en":"Dismiss", "de":"Schließen"},
    "whatsApp": {
        "it": "https://chat.whatsapp.com/BObKitDsq6QKWvyERsVxcs",
        "en":"https://chat.whatsapp.com/LS0wFaP3yfqII8Vo88IAjp",
        "de":"https://chat.whatsapp.com/CbCWDxeKokf1DgJnq5DdeJ"},
    "simply": {"it": "Semplicemente", "en":"Simply", "de": "Einfach"},
    "simplyTalk": {"it": "Comunicare è facile!", "en":"Simply, Talk.", "de": "Einfach, Sprechen."},
    "aClipTalkApp": {"it": "Un'app ClipTalk per liberare la tua creatività e le tue idee.", "en":"A ClipTalk App to unleash your creativity and ideas.", "de": "Eine ClipTalk-App, um Ihre Kreativität und Ideen zu entfesseln."},
    "justStartChoosing": {"it": "Inizia scegliendo da una selezione predefinita o creando le tue carte uniche!", "en":"Just start choosing from a default selection or create your unique cards!", "de": "Wählen Sie einfach aus einer Standardauswahl oder erstellen Sie Ihre eigenen einzigartigen Karten!"},
    "highlyCustomizable": {"it": "Altamente personalizzabile", "en":"Highly customizable", "de": "Hochgradig anpassbar"},
    "easyToUse": {"it": "Facile da usare", "en":"Easy to use", "de": "Einfach zu bedienen"},
    "saveYourTime": {"it": "Generatore di carte automatico", "en":"Automatic cards generator", "de": "Automatischer Karten-Generator"},
    "freeCards": {"it":"Gratis fino a "+_freeCards+" carte. Nessun limite di tempo",
                  "en":"Free up to "+_freeCards+" cards. No time limit.",
                  "de":"Kostenlos bis zu "+_freeCards+" Karten. Keine Zeitbegrenzung."},
    "quickCardCreation": {"it": "Creazione rapida di carte", "en":"Quick Card Creation", "de": "Schnelle Kartenerstellung"},
    "createCardsThroughSimplyWriting": {"it": "Crea carte semplicemente scrivendo", "en":"Create cards through simply writing", "de": "Erstellen Sie Karten, indem Sie einfach schreiben"},
    "sentences": {"it": "frasi", "en":"sentences", "de": "Sätze"},
    "lists": {"it": "liste", "en":"lists", "de": "Listen"},
    "andShareThemThrough": {"it": "e condividile tramite", "en":"and share them through", "de": "und teilen Sie sie durch"},
    "copyPasting":{"it":"copia e incolla", "en":"copy-pasting", "de": "Kopieren und Einfügen"},
    "whatDoYouWantToCopy": {"it": "Cosa vuoi copiare?", "en":"What do you want to copy?", "de": "Was möchten Sie kopieren?"},
    "copyLink": {"it": "Copia", "en":"Copy", "de": "Kopieren"},
    "linkCopied": {"it": "Link copiato negli appunti!", "en":"Link copied to clipboard!", "de": "Link in die Zwischenablage kopiert!"},
    "getItOnGPlay":{"it":"Scarica l'app da Google Play",
                    "en":"Get it on Google Play",
                    "de":"Holen Sie es sich bei Google Play"},
    "useTheWebApp":{"it":"Usa la versione web",
                    "en":"Use the web version",
                    "de":"Verwenden Sie die Webversion"},
    "easyCustomization": {"it": "Facile da personalizzare", "en":"Easy Customization", "de": "Einfache Anpassung"},
    "customizeEachCard": {"it": "Personalizza ogni carta cambiando", "en":"Customize each card by changing", "de": "Passen Sie jede Karte an, indem Sie Eigenschaften ändern, wie "},
    "sound": {"it": "suono", "en":"sound", "de": "Ton"},
    "color": {"it":"colore","en":"color","de":"Farbe"},
    "colorCapital": {"it":"Colore","en":"Color","de":"Farbe"},
    "and": {"it":"e", "en":"and", "de":"und"},
    "toMeetYourNeeds": {
        "it": "per soddisfare le tue uniche esigenze",
        "en": "to meet your unique needs",
        "de": "um Ihren einzigartigen Bedürfnissen gerecht zu werden"},
    "syncAcrossDevices": {"it": "Sincronizza tra dispositivi", "en":"Sync Across Devices", "de": "Synchronisieren Sie über Geräte hinweg"},
    "accessTheSameCards": {"it": "Accedi alle stesse carte su più dispositivi con", "en":"Access the same cards across multiple devices with", "de": "Greifen Sie auf dieselben Karten auf mehreren Geräten mit"},
    "cloudSynchronization": {"it": "sincronizzazione cloud", "en":"cloud synchronization", "de": "Cloud-Synchronisation"},
    "accessFrequentSentences": {"it": "Accedi a frasi frequenti", "en":"Access Frequent Sentences", "de": "Greifen Sie auf häufige Sätze zu"},
    "facilitateCommunication": {"it": "Facilita la comunicazione con l'accesso a", "en":"Facilitate communication with easy access to", "de": "Erleichtern Sie die Kommunikation mit einfachem Zugang zu"},
    "frequentSentences": {"it": "frasi frequenti", "en":"frequent sentences", "de": "häufige Sätze"},
    "andMuchMore": {"it": "E molto altro!", "en":"And much more!", "de": "Und vieles mehr!"},
    "andMuchMoreSentence": {
        "it": "Quiz, Statistiche, integrazione video Youtube e carte predefinite...",
        "en": "Quiz, Statistics, Youtube video integration and predefined cards...", 
        "de": "Quiz, Statistiken, Youtube-Video-Integration und vordefinierte Karten..."},
    "notHappy": {
        "it": "Insoddisfatto?",
        "en": "Not happy?",
        "de": "Nicht glücklich?"},
    "hello": {
        "it": "Ciao!",
        "en": "Hello!",
        "de": "Hallo!",
    },
    "joinTheCommunity": {
        "it": "Unisciti alla nostra community",
        "en": "Join the community",
        "de": "Treten Sie der Community bei"},
    "writeHere":{
        "it": "Scrivi qui...",
        "en": "Write here...",
        "de": "Schreiben Sie hier..."
    },
    
    "toShareYourFeedback": {
        "it": "per condividere il tuo feedback e aiutarci a migliorare!",
        "en": "to share your feedback and help us improve!",
        "de": "um Ihr Feedback zu teilen und uns zu helfen, uns zu verbessern!"},
    "videoURLPlaceholder": {"it":"Inserisci l'URL del video Youtube", "en":"Insert the Youtube video URL", "de":"Geben Sie die Youtube-Video-URL ein"},
    "dsappVideoURL": {"it": "https://www.youtube.com/watch?v=JgFY1JWk2Zs", "en":"https://www.youtube.com/watch?v=LsH80zo0LBM", "de": "https://www.youtube.com/watch?v=jl1I5mWsmHo"},
    "next": {"it": "Avanti", "en":"Next", "de": "Weiter"},
    "close": {"it": "Chiudi", "en":"Close", "de": "Schließen"},
    "directionOfBar": {"it": "Direzione frase", "en":"Sentence direction", "de": "Satzrichtung"},
    "storyMode": {"it": "Racconta una storia", "en":"Tell a story", "de": "Erzählen Sie eine Geschichte"},
    "listMode":{ "it": "Elenca parole", "en":"List words", "de": "Liste Wörter"},
    "talkMode": {"it": "Crea un dialogo", "en":"Create a dialogue", "de": "Erstellen Sie einen Dialog"},
    "writeHereYourStory": {"it": "Scrivi qui la tua storia...", "en":"Write here your story...", "de": "Schreiben Sie hier Ihre Geschichte..."},
    "writeHereYourListOneWordPerLine": {"it": "Scrivi\nqui\nla tua lista\nuna carta per riga...", "en":"Write here\nyour list\none card per line...", "de": "Schreiben Sie\nhier Ihre Liste\neine Karte pro Zeile..."},
    "DSAPPInAction": {"it": "DSApp in azione...", "en":"DSApp in action...", "de": "DSApp in Aktion..."},
    "ifYouAreUnsatisfied": {"it": "Se siete insoddisfatti...", "en":"If you are unsatisfied...", "de": "Wenn Sie unzufrieden sind..."},
    "ifYouAreUnsatisfiedMessage": {"it": "...fatecelo sapere tramite l'app o su Facebook! Siamo pronti a modificarla secondo le vostre specifiche esigenze. Inoltre, potete annullare l'abbonamento in qualsiasi momento.", 
                                      "en":"...let us know through the app or on Facebook! We are ready to modify it according to your specific needs. Also, you can cancel the subscription at any time.",
                                      "de":"...lassen Sie es uns über die App oder auf Facebook wissen! Wir sind bereit, es gemäß Ihren spezifischen Anforderungen zu ändern. Außerdem können Sie das Abonnement jederzeit kündigen."},
    
    "writeHereYourDialogue": {"it": "Scrivi qui le frasi da comporre, esempio:\nMi piace giocare con la palla\nVoglio mangiare il panino\nMi fa male la testa\n...", "en":"Write here the sentences to compose, example:\nI like playing with the ball\nI want to eat the sandwich\nMy head hurts\n...", "de": "Schreiben Sie hier die zu komponierenden Sätze, Beispiel:\nIch spiele gerne mit dem Ball\nIch möchte das Sandwich essen\nMein Kopf tut weh\n..."},
    "addCards": {"it": "Aggiungi alle carte del ramo", "en":"Add to existing cards", "de": "Zu vorhandenen Karten hinzufügen"},
    "replaceCards": {"it": "Sostituisci le carte del ramo", "en":"Replace existing cards", "de": "Vorhandene Karten ersetzen"},
    "showOnceSentenceAtATime": {"it": "Mostra una frase alla volta / una frase per ramo", "en":"Show one sentence at a time / one sentence per branch", "de": "Zeigen Sie einen Satz pro Mal / einen Satz pro Zweig"},
    "share": {"it": "Condividi", "en":"Share", "de": "Teilen"},
    "shareBranchCards": {"it": "Condivisione delle carte del ramo", "en":"Share branch cards", "de": "Teilen Sie Karten"},
    "justPaste": {"it": "Incolla", "en":"Paste", "de": "Einfügen"},
    "paste": {"it": "Incolla qui", "en":"Paste here", "de": "Hier einfügen"},
    "pasteHereReplace": {"it": "Sostituisci e incolla consecutivamente", "en":"Replace and paste consecutively", "de": "Ersetzen und aufeinanderfolgend einfügen"},
    "pasteHereShift": {"it": "Sposta celle successive", "en":"Make consecutive and shift following cells", "de": "Machen Sie aufeinanderfolgend und verschieben Sie die folgenden Zellen"},
    "pasteHereRelative": {"it": "Sostituisci celle di destinazione", "en":"Replace destination cells", "de": "Ersetzen Sie die Zielzellen"},
    "shareBranchCardsLimitExceededAlert": {"it": "Troppo grande per essere condiviso in modalità Minimale. Riduci il numero di carte e riprova.", "en":"Too big to be shared in Minimal mode. Reduce the number of cards and try again.", "de": "Zu groß, um im Minimalmodus geteilt zu werden. Reduzieren Sie die Anzahl der Karten und versuchen Sie es erneut."},
    "prefixForAIAssistant": {"it": "Spiega in breve a un bambino di 10 anni: ", "en":"Explain briefly to a 10 years old child: ", "de": "Erklären Sie kurz einem 10-jährigen Kind: "},
    "tooManyRequests": {"it": "Hai superato il limite di richieste.", "en":"You have exceeded the requests limit.", "de": "Sie haben das Anforderungslimit überschritten."},
    "remainingRequests": {"it": "Richieste rimanenti: ", "en":"Remaining requests: ", "de": "Verbleibende Anfragen: "},
    "switchAccount": {"it": "Cambia profilo", "en":"Switch account", "de": "Konto wechseln"},
    "paymentSuccess": {"it": "Grazie per avere sottoscritto l'abbonamento.", "en":"Thank you for subscribing.", "de": "Vielen Dank für Ihr Abonnement."},
    "updatesMayTakeAWhile": {"it": "Le modifiche potrebbero richiedere qualche minuto per essere visibili.", "en":"Updates may take a while to be visible.", "de": "Aktualisierungen können einige Minuten dauern, um sichtbar zu werden."},
    "understood": {"it": "Vai all'accesso", "en":"Go to login", "de": "Zum Login gehen"},
    "premiumUsersHave10NewDailyRequestsWhileBaseUsers5InTotal": {"it": "Gli utenti premium hanno 10 nuove richieste giornaliere, mentre gli utenti base 5 in totale", "en":"Premium users have 10 new daily requests, while base users 5 in total", "de": "Premium-Benutzer haben 10 neue tägliche Anfragen, während Basisbenutzer insgesamt 5 haben"},
    "dragAndDropFilesHereOrClickToSelect": {"it": "Trascina e rilascia i file qui o clicca per selezionarli", "en":"Drag and drop files here or click to select", "de": "Ziehen Sie Dateien hierher oder klicken Sie zum Auswählen"},
    "dropFilesHere": {"it": "Rilascia i file qui", "en":"Drop files here", "de": "Dateien hier ablegen"},
    "uploadPecs": {"it": "Carica Pecs", "en":"Upload Pecs", "de": "Pecs hochladen"},
    "minimalSharing": {"it": "Minimale", "en":"Minimal", "de": "Minimal"},
    "onlyCurrentBranchAndIgnoreImages": {"it": "Solo ramo corrente. Ignora immagini", "en":"Only current branch. Ignore images", "de": "Nur aktueller Zweig. Bilder ignorieren"},
    "fullSharing": {"it": "Totale", "en":"Full", "de": "Voll"},
    "includeSubBranchesAndImages": {"it": "Includi sotto-livelli e immagini", "en":"Include sub-branches and images", "de": "Unterzweige und Bilder einbeziehen"},
    "download": {"it": "Scarica", "en":"Download", "de": "Herunterladen"},
    "goTo": {"it": "Vai...", "en":"Go to...", "de": "Gehe zu..."},
    "loadFrom": {"it": "Carica da...", "en":"Load from...", "de": "Laden von..."},
    "loadDotPecsFile": {"it": "Carica da file .pecs", "en":"Load from .pecs file", "de": "Aus .pecs-Datei laden"},
    "or": {"it": "oppure", "en":"or", "de": "oder"},
    "textFromImage": {"it": "Testo da immagine", "en":"Text from image", "de": "Text aus Bild"},
    "otherFormats": {"it": "Altri formati", "en":"Other formats", "de": "Andere Formate"},
    "asPDF": {"it": "PDF", "en":"As PDF", "de": "Als PDF"},
    "nrCols": {"it": "Colonne", "en":"Columns", "de": "Spalten"},
    "reworkText": {"it": "Rielabora testo", "en":"Rework text", "de": "Text überarbeiten"},
    "prefixForAIAssistantRewrite": {"it": "Correggi e rielabora questo testo per un bambino di 9 anni massimo 140 token: ", "en":"Correct and rework this text for a 9 years old child in less than 140 tokens: ", "de": "Korrigieren und überarbeiten Sie diesen Text für ein 9-jähriges Kind in weniger als 140 tokens: "},
    "pdfMargin": {"it": "Margine (aumentalo se il contenuto è spezzato tra due pagine)", "en":"Margin (increase it if the content is broken between two pages)", "de": "Rand (erhöhen Sie ihn, wenn der Inhalt zwischen zwei Seiten unterbrochen ist)"},
    "addWhiteSpaceOverSelectedCards": {"it": "Aggiungere spazio sopra le carte selezionate?", "en":"Add white space over selected cards?", "de": "Weißraum über ausgewählten Karten hinzufügen?"},
    "activities": {"it": "Attività", "en":"Activities", "de": "Aktivitäten"},
    "visualComprehensionTask": {"it": "Comprensione visiva", "en":"Visual comprehension", "de": "Visuelles Verständnis"}, 
    "visualComprehensionTaskCreation": {"it": "Le carte che hai appena selezionato saranno le DOMANDE. Quelle invece contenute (nidificate) al loro interno saranno le POSSIBILI RISPOSTE.",
                                        "en": "The cards you just selected will be the QUESTIONS. Those instead contained (nested) within them will be the POSSIBLE ANSWERS.",
                                        "de": "Die von Ihnen ausgewählten Karten werden die FRAGEN sein. Diejenigen, die stattdessen darin enthalten sind (verschachtelt), werden die MÖGLICHEN ANTWORTEN sein."},
    "visualComprehensionTaskInstructions": {"it": "Osserva l'immagine e rispondi alle domande! Durante l'attività, puoi entrare in modalità amministratore per cambiare le carte selezionate con le altre disponibili nell'apposita libreria.",
                                                "en": "Observe the image and answer the questions! During the activity, you can enter admin mode to change the selected cards with the others available in the appropriate library.",
                                                "de": "Betrachten Sie das Bild und beantworten Sie die Fragen! Während der Aktivität können Sie den Admin-Modus eingeben, um die ausgewählten Karten mit den anderen in der entsprechenden Bibliothek verfügbaren Karten zu ändern."},
    "taskDescription": {"it": "Descrizione", "en":"Description", "de": "Beschreibung"},
    "taskGeneration": {"it": "Processo di creazione", "en":"Generation process", "de": "Generierungsprozess"},
    "youSelected": {"it": "Hai selezionato", "en":"You selected", "de": "Sie haben ausgewählt"},
    "soTheQuestionsWillBe": {"it": "Quindi le domande saranno", "en":"So the questions will be", "de": "Also werden die Fragen sein"},
    "forExample": {"it": "Per esempio", "en":"For example", "de": "Zum Beispiel"},
    "theAnswersToTheQuestion": {"it": "Possibili risposte alla domanda", "en":"Possible answers to the question", "de": "Mögliche Antworten auf die Frage"},
    "pleaseEnsureAllSelectedCardsHaveSubcards": { "it": "Nessuna! Assicurati che le carte selezionate contentano altre carte prima di procedere!",
                                                    "en": "None! Make sure the selected cards contain other cards before proceeding!",
                                                    "de": "Keiner! Stellen Sie sicher, dass die ausgewählten Karten andere Karten enthalten, bevor Sie fortfahren!"},
    "selectActivity": {"it": "Seleziona attività", "en":"Select activity", "de": "Aktivität auswählen"},
    "downloadActivities": {"it": "Scarica attività", "en":"Download activities", "de": "Aktivitäten herunterladen"},
    "downloadOtherAvailableActivities": {"it": "Scarica altre "+(_TEMPLATE_A_ACTIVITIES_NR-1)+" attività disponibili", "en":"Download other "+(_TEMPLATE_A_ACTIVITIES_NR-1)+" available activities", "de": "Laden Sie andere "+(_TEMPLATE_A_ACTIVITIES_NR-1)+" verfügbare Aktivitäten herunter"},
    "experienceAnActivity": {"it": "Prova un'attività", "en":"Experience an activity", "de": "Erleben Sie eine Aktivität"},
    "premiumCardPlaceholderMessage": {"it": "Puoi visualizzare solo "+_freeCards+" carte come utente base.", "en":"You can view only "+_freeCards+" cards as a base user.", "de": "Sie können nur "+_freeCards+" Karten als Basisbenutzer anzeigen."},
    "premiumFeature": {"it": "Funzionalità premium", "en":"Premium feature", "de": "Premium-Funktion"},
    "premiumFeatureDescription": {"it": "Questa funzionalità è disponibile solo per gli utenti premium.", "en":"This feature is available only for premium users.", "de": "Diese Funktion ist nur für Premium-Benutzer verfügbar."},
    "loginMessage": {"it": "Per accedere, selezionare uno dei seguenti metodi di autenticazione.", "en":"To login, select one of the following authentication methods.", "de": "Um sich anzumelden, wählen Sie eine der folgenden Authentifizierungsmethoden."},
    "loginWithEmailAndPassword": {"it": "Accedi con email e password", "en":"Login with email and password", "de": "Mit E-Mail und Passwort anmelden"},
    "accountAlreadyExists": {"it": "L'account esiste già. Se hai dimenticato la password, puoi recuperarla cliccando su 'Reset Password'", "en":"Account already exists. If you forgot the password, you can recover it by clicking on 'Reset Password'", "de": "Konto existiert bereits. Wenn Sie das Passwort vergessen haben, können Sie es wiederherstellen, indem Sie auf 'Passwort zurücksetzen' klicken"},
    "invalidEmail": {"it": "Email non valida", "en":"Invalid email", "de": "Ungültige E-Mail"},
    "register": {"it": "Registrati con email e password", "en":"Register with email and password", "de": "Mit E-Mail und Passwort registrieren"},
    "error": {"it": "Errore", "en":"Error", "de": "Fehler"},
    "missingPassword": {"it": "Password mancante", "en":"Missing password", "de": "Passwort fehlt"},
    "undefinedAccount": {"it": "Credenziali errate o account inesistente. Se hai dimenticato la password, puoi recuperarla cliccando su 'Reset Password'", "en":"Wrong credentials or undefined account. If you forgot the password, you can recover it by clicking on 'Reset Password'", "de": "Falsche Anmeldeinformationen oder nicht definiertes Konto. Wenn Sie das Passwort vergessen haben, können Sie es wiederherstellen, indem Sie auf 'Passwort zurücksetzen' klicken"},
    "weakPassword": {"it": "Password debole", "en":"Weak password", "de": "Schwaches Passwort"},
    "resetPassword": {"it": "Reset password", "en":"Reset password", "de": "Passwort zurücksetzen"},
    "passwordResetSent": {"it": "Email per il reset della password inviata all'indirizzo specificato.", "en":"Password reset email sent to the specified address.", "de": "E-Mail zum Zurücksetzen des Passworts an die angegebene Adresse gesendet."},
    "userNotFound": {"it": "Utente non trovato. Clicca su 'Registrati' per creare un nuovo account", "en":"User not found. Click on 'Register' to create a new account", "de": "Benutzer nicht gefunden. Klicken Sie auf 'Registrieren', um ein neues Konto zu erstellen"},
    "sentRegistrationEmail": {"it": "Email di registrazione inviata all'indirizzo specificato. Clicca sul link contenuto per confermare la registrazione.", "en":"Registration email sent to the specified address. Click on the contained link to confirm the registration.", "de": "Registrierungs-E-Mail an die angegebene Adresse gesendet. Klicken Sie auf den enthaltenen Link, um die Registrierung zu bestätigen."},
    "suggested": {"it": "consigliato", "en":"suggested", "de": "vorgeschlagen"},
    "loginWithGooglePopup": {"it": "Accedi con Google (Popup)", "en":"Login with Google (Popup)", "de": "Mit Google anmelden (Popup)"},
    "loginWithFacebook": {"it": "Accedi con Facebook", "en":"Login with Facebook", "de": "Mit Facebook anmelden"},
    "accountExistsWithDifferentProvider": {"it": "Esiste già un account con la stessa email ma con un provider di autenticazione diverso. Prova ad accedere con un altro metodo.", "en":"An account already exists with the same email but with a different authentication provider. Try to login with another method.", "de": "Es existiert bereits ein Konto mit derselben E-Mail, jedoch mit einem anderen Authentifizierungsanbieter. Versuchen Sie, sich mit einer anderen Methode anzumelden."},
    "inCaseOfProblemsContactUs": {"it": "In caso di problemi, contattaci ", "en":"In case of problems, contact us ", "de": "Bei Problemen kontaktieren Sie uns "},
    "here": {"it": "qui", "en":"here", "de": "hier"},
    "orOn": {"it": "o su ", "en":"or on ", "de": "oder auf "},
    "allowCardsRepetition": {"it": "Permetti ripetizione carte", "en":"Allow cards repetition", "de": "Kartenwiederholung zulassen"},
    "exportInProgress": {"it": "Esportazione in corso...", "en":"Export in progress...", "de": "Export im Gange..."},
    "waitTime": {"it": "Stima tempo di attesa: ", "en":"Estimated wait time: ", "de": "Geschätzte Wartezeit: "},
    "seconds": {"it": "secondi", "en":"seconds", "de": "Sekunden"},
    "all": {"it": "Tutto (carta, audio, immagine)", "en":"All (card, audio, image)", "de": "Alle (Karte, Audio, Bild)"},
    "cardOnly": {"it": "Solo carta (immagini e audio rimangono associati)", "en":"Card only (images and audio remain associated)", "de": "Nur Karte (Bilder und Audio bleiben zugeordnet)"},
    "copy": {"it": "Copia", "en":"Copy", "de": "Kopieren"},
    "move": {"it": "Sposta", "en":"Move", "de": "Verschieben"},
    "nextImage": {"it": "Altra immagine", "en":"Next image", "de": "Nächstes Bild"},
    "colorIt": {"it": "Colora", "en":"Color it", "de": "Färbe es"},
    "chooseImage": {"it": "Carica media", "en":"Upload media", "de": "Medien hochladen"},
    "editText": {"it": "Cambia testo", "en":"Edit text", "de": "Text bearbeiten"},
    "playSound": {"it": "Riproduci", "en":"Play", "de": "Abspielen"},
    "open": {"it": "Apri", "en":"Open", "de": "Öffnen"},
    "hide": {"it": "Nascondi", "en":"Hide", "de": "Verstecken"},
    "show": {"it": "Mostra", "en":"Show", "de": "Anzeigen"},
    "everything": {"it": "Tutto", "en":"Everything", "de": "Alles"},
    "selectAll": {"it": "Sel. tutto", "en":"Sel. all", "de": "Alles ausw."},
    "unselectAll": {"it": "Des. tutto", "en":"Uns. all", "de": "Alles abw."},
    "library": {"it": "Libreria", "en":"Library", "de": "Bibliothek"},
    "ignoreNewLines": {"it": "Ignora i ritorni a capo", "en":"Exclude carriage returns", "de": "Ohne Wagenrückläufe"},
    "undoDelete": {"it": "Annulla eliminazione", "en":"Undo delete", "de": "Löschen rückgängig machen"},
    "pasteBeforeSelectedCards": {"it": "Incolla prima delle carte selezionate", "en":"Paste before selected cards", "de": "Vor ausgewählten Karten einfügen"},
    "pasteInsideSelectedCards": {"it": "Incolla dentro le carte selezionate", "en":"Paste inside selected cards", "de": "In ausgewählte Karten einfügen"},
    "dontPaste": {"it": "Non incollare", "en":"Don't paste", "de": "Nicht einfügen"},
    "undoCopy": {"it": "Annulla copia", "en":"Undo copy", "de": "Kopieren rückgängig machen"},
    "pasteInCurrentBranch": {"it": "Incolla alla fine del ramo corrente", "en":"Paste at the end of the current branch", "de": "Am Ende des aktuellen Zweigs einfügen"},
    "deleteOptions": {"it": "Elimina (Opzioni)", "en":"Delete options", "de": "Löschoptionen"},
    "quickAccessWithGoogleOrFacebook": {"it": "Accesso rapido (Google o Facebook)", "en":"Quick access (Google or Facebook)", "de": "Schneller Zugriff (Google oder Facebook)"},
    "accessWithEmailAndPassword": {"it": "Email e password", "en":"Email and password", "de": "E-Mail und Passwort"},
    "orIfItIsTheFirstTime": {"it": "oppure se è la prima volta", "en":"or if it is the first time", "de": "oder wenn es das erste Mal ist"}, 
    "loginChecklistTitle": {
        "it": "Prima di procedere...",
        "en": "Before proceeding...",
        "de": "Vor dem Fortfahren...",
      },
      "loginChecklistItem1": {
        "it": "Se vuoi accedere con Google, prova entrambi i pulsanti di accesso (senza/con Popup).",
        "en": "If you want to log in with Google, try both login buttons (without/with Popup).",
        "de": "Wenn Sie sich mit Google anmelden möchten, probieren Sie beide Anmeldebuttons aus (ohne/mit Popup).",
      },
      "loginChecklistItem2": {
        "it": "Attenziona eventuali messaggi di errore. Annotali o fai una foto/screenshot se l'assistenza lo richiederà.",
        "en": "Pay attention to any error messages. Note them down or take a photo/screenshot if support requests it.",
        "de": "Achten Sie auf eventuelle Fehlermeldungen. Notieren Sie diese oder machen Sie ein Foto/Screenshot, falls der Support dies anfordert.",
      },
      "loginChecklistItem3": {
        "it": "Controlla che la tua connessione ad internet funzioni.",
        "en": "Check that your internet connection is working.",
        "de": "Überprüfen Sie, ob Ihre Internetverbindung funktioniert.",
      },
      "reportLoginIssue": {
        "it": "Segnala problema di accesso",
        "en": "Report login issue",
        "de": "Problem bei der Anmeldung melden",
      },
      "reportOtherIssue": {
        "it": "Segnala altro problema",
        "en": "Report another issue",
        "de": "Anderes Problem melden",
      },
    "thisWindowCanBeClosedIn": {"it": "Questa finestra potrà essere chiusa in", "en":"This window can be closed in", "de": "Dieses Fenster kann geschlossen werden in"},
    "wantToShowcaseYourAdsOrContentHere": {"it": "Vuoi mostrare i tuoi annunci o contenuti qui?", "en":"Want to showcase your ads or content here?", "de": "Möchten Sie Ihre Anzeigen oder Inhalte hier präsentieren?"},
    "clickTheButtonBelowToGetStarted": {"it": "Clicca il pulsante qui sotto per iniziare", "en":"Click the button below to get started", "de": "Klicken Sie auf die Schaltfläche unten, um zu beginnen"},
    "advertiseHere": {"it": "Pubblicizza qui", "en":"Advertise here", "de": "Hier werben"},
    "becomeAPremiumUserToBlockAllAds": {"it": "Per bloccare tutti gli annunci, diventa un utente premium", "en":"Become a premium user to block all ads", "de": "Werden Sie Premium-Benutzer, um alle Anzeigen zu blockieren"},
    "ads": {"it": "Pubblicità", "en":"Ads", "de": "Anzeigen"},
    "byClickingHere": {"it": "cliccando qui", "en":"by clicking here", "de": "um hier zu klicken"},
    "errorImportingContent": {"it": "Errore nell'importazione del contenuto", "en":"Error importing content", "de": "Fehler beim Importieren von Inhalten"},
    "stories": {"it": "Storie", "en":"Stories", "de": "Geschichten"},
    "search": {"it": "Cerca...", "en":"Search...", "de": "Suche..."},
    "name": {"it": "Nome carte CAA" , "en":"AAC cards name", "de": "AAC-Kartenname"},
    "links": {"it": "Scarica tramite links", "en":"Download via links", "de": "Über Links herunterladen"},
    "Facebook": {"it": "Facebook", "en":"Facebook", "de": "Facebook"},
    "noContentFound": {"it": "Nessun contenuto trovato", "en":"No content found", "de": "Keine Inhalte gefunden"},
    "magnify": {"it": "Ingrandisci", "en":"Magnify", "de": "Vergrößern"},
    "reduce": {"it": "Riduci", "en":"Reduce", "de": "Reduzieren"},
    "startHere": {"it": "Inizio qui", "en":"Start here", "de": "Hier anfangen"},
    "noResult": {"it": "Nessun risultato", "en":"No result", "de": "Kein Ergebnis"},
    "doYouWantToSelectAlSimilarCards": {"it": "Selezionare tutte le carte simili?", "en":"Select all similar cards?", "de": "Alle ähnlichen Karten auswählen?"},
    "selectSimilarCards": {"it": "Sel. carte simili", "en":"Sel. similar cards", "de": "Ähnliche Karten auswählen"},
    "inTotal": {"it": "in totale", "en":"in total", "de": "insgesamt"},
    "doYouWantToSelect": {"it": "Selezionare tutte carte", "en":"Select all cards", "de": "Alle Karten auswählen"},
    "untilNextNewLine": {"it": "fino a nuova riga", "en": "until new line", "de": "bis neue Zeile"},
    "sameColorCards": {"it": "dello stesso colore", "en":"with same color", "de": "derselben Farbe"},
    "untilNextSelectedCard": {"it": "Seleziona fino a...", "en":"Select until...", "de": "Wählen Sie bis zur..."},
    "selectAllIntermediateCards": {"it": "Sel. tutte le carte intermedie", "en":"Sel. all intermediate cards", "de": "Alle Zwischenkarten auswählen"},
    "ask": {"it": "Chiedi", "en":"Ask", "de": "Fragen"},
    "notFindingWhatYouAreLookingFor": {"it": "Non trovi quello che cerchi?", "en":"Not finding what you are looking for?", "de": "Nicht das finden, wonach Sie suchen?"},
    "tellUsMore": {"it": "Dicci di più...", "en":"Tell us more...", "de": "Erzähl uns mehr..."},
    "selectCategory": {"it": "Seleziona categoria", "en":"Select category", "de": "Kategorie auswählen"},
    "selectOneOption": {"it": "Seleziona una opzione", "en":"Select one option", "de": "Wählen Sie eine Option"},
    "requestNewFeature": {"it": "Richiedi una nuova funzionalità", "en":"Request a new feature", "de": "Fordern Sie eine neue Funktion an"},
    "improveExistingFeature": {"it": "Migliora una funzionalità esistente", "en":"Improve an existing feature", "de": "Verbessern Sie eine vorhandene Funktion"},
    "askForContent": {"it": "Richiedi contenuti", "en":"Ask for content", "de": "Fragen Sie nach Inhalten"},
    "reportBug": {"it": "Segnala un malfunzionamento", "en":"Report a bug", "de": "Melden Sie einen Fehler"},
    "other": {"it": "Altro", "en":"Other", "de": "Andere"},
    "describeHereYourRequest": {"it": "Descrivi qui la tua richiesta...", "en":"Describe here your request...", "de": "Beschreiben Sie hier Ihre Anfrage..."},
    "frequentlyAskedQuestions": {"it": "Domande frequenti", "en":"Frequently asked questions", "de": "Häufig gestellte Fragen"},
    "askOnOfficialPages": {"it": "Se hai bisogno di aiuto, chiedi sulle pagine ufficiali", "en":"If you need help, ask on the official pages", "de": "Wenn Sie Hilfe benötigen, fragen Sie auf den offiziellen Seiten"},
    "feedbackLengthError": {"it": "Il feedback deve essere lungo almeno "+_feedbackMinLen+" caratteri", "en":"Feedback must be at least "+_feedbackMinLen+" characters long", "de": "Feedback muss mindestens "+_feedbackMinLen+" Zeichen lang sein"},
    "feedbackTypeError": {"it": "Seleziona una tipologia di feedback", "en":"Select a feedback type", "de": "Wählen Sie einen Feedback-Typ"},
    "submissionError": {"it": "Errore nell'invio del feedback. Riprova più tardi.", "en":"Error submitting feedback. Try again later.", "de": "Fehler beim Senden von Feedback. Versuchen Sie es später noch einmal."},
    "goToStartingPoint": {"it": "Vai all'inizio", "en": "Go to start", "de": "Gehe zum Start"},
    "quickInsert": {"it": "Nuova Carta", "en": "New Card", "de": "Neue Karte"},
    "invertImgTxt": {"it": "Inverti", "en": "Flip", "de": "Umkehren"},
    "currentBranch": {"it": "Ramo corrente", "en": "Current branch", "de": "Aktueller Zweig"},
    "nowSelectEndCard": {"it": "Ora seleziona la carta finale", "en": "Now select the end card", "de": "Wählen Sie nun die Endkarte aus"},
    "toPasteBeforeOrInsideACardSelectTheCardFirstAndClickOnPasteButton": {"it": "Per incollare prima o dentro una carta, seleziona prima la carta e poi clicca su 'Incolla (Opzioni)'. Se vuoi incollare alla fine del ramo corrente clicca direttamente sul pulsante 'Incolla (Opzioni)'", "en": "To paste before or inside a card, first select the card and then click on 'Paste options'. If you want to paste at the end of the current branch, click directly on the 'Paste options' button", "de": "Um vor oder in eine Karte einzufügen, wählen Sie zuerst die Karte aus und klicken Sie dann auf 'Einfügeoptionen'. Wenn Sie am Ende des aktuellen Zweigs einfügen möchten, klicken Sie direkt auf die Schaltfläche 'Einfügeoptionen'"},
    "requestContent": {"it": "Richiedi contenuto", "en": "Request content", "de": "Inhalte anfordern"},
    "onlySubscribedUsersCanUseThisFeature": {"it": "Solo gli utenti abbonati possono utilizzare questa funzionalità", "en": "Only subscribed users can use this feature", "de": "Nur abonnierte Benutzer können diese Funktion verwenden"},
    "yourRequestHasBeenSentSuccessfullyYouWillReceiveAnEmailAsSoonAsTheContentIsAvailable": {"it": "La tua richiesta è stata inviata con successo. Riceverai una email non appena il contenuto sarà disponibile.", "en": "Your request has been sent successfully. You will receive an email as soon as the content is available.", "de": "Ihre Anfrage wurde erfolgreich gesendet. Sie erhalten eine E-Mail, sobald der Inhalt verfügbar ist."},
    "doYouWantToRemoveTheNewLinesInTheSelectedRange": {"it": "Vuoi rimuovere i ritorni a capo nell'intervallo selezionato?", "en": "Do you want to remove the new lines in the selected range?", "de": "Möchten Sie die Zeilenumbrüche im ausgewählten Bereich entfernen?"},
    "cardBackground": {"it": "Carta" , "en":"Card", "de": "Karte"},
    "borderColor": {"it": "Bordo" , "en":"Border", "de": "Rand"},
    "textColor": {"it": "Testo" , "en":"Text", "de": "Text"},
    "textBackgroundColor": {"it": "Sfondo testo" , "en":"Text background", "de": "Text Hintergrund"},
    "plusBorderSize": {"it": "Bordo" , "en":"Border", "de": "Rand"},
    "minusBorderSize": {"it": "Bordo" , "en":"Border", "de": "Rand"},
    "editMode": {"it": "Modifica" , "en":"Edit", "de": "Bearbeiten"},
    "artistMode": {"it": "Mod. Artista", "en":"Artist mode", "de": "Künstlermodus"},
    "moveMode": {"it": "Sposta" , "en":"Move", "de": "Verschieben"},
    "subscriptionRequired": {"it": "Abbonamento richiesto", "en":"Subscription required", "de": "Abonnement erforderlich"},
    "featureForSubscribedUsersOnly": {"it": "Funzionalità riservata agli utenti abbonati", "en":"Feature for subscribed users only", "de": "Funktion nur für Abonnenten"},
    "thisFeatureIsAvailableForSubscribedUsersOnly": {"it": "La modalità artista è disponibile solo per gli utenti abbonati. Nel frattempo, puoi guardare un breve video su come usarla!", "en":"Artist mode is available only for subscribed users. Meanwhile, you can watch a short video on how to use it!", "de": "Der Künstlermodus ist nur für Abonnenten verfügbar. In der Zwischenzeit können Sie sich ein kurzes Video ansehen, wie Sie es verwenden!"},
    "watchVideo": {"it": "Guarda il video", "en":"Watch video", "de": "Video ansehen"},
    "randomOrderText": {"it": "Ordine casuale", "en":"Random order", "de": "Zufällige Reihenfolge"},
    "cardsAsMultipleChoice": {"it": "Usa come scelta multipla", "en":"Use as multiple choice", "de": "Als Multiple Choice verwenden"},
    "cardsNotAsMultipleChoice": {"it": "Annulla scelta multipla", "en":"Cancel multiple choice", "de": "Mehrfachauswahl abbrechen"},
    "cardsProps": {"it": "Proprietà carte", "en":"Cards properties", "de": "Karteneigenschaften"},
    "cardsActivities": {"it": "Crea attività da carte", "en":"Create activities from cards", "de": "Aktivitäten aus Karten erstellen"},
    "doNotUndo": {"it": "Non annullare", "en":"Do not undo", "de": "Nicht rückgängig machen"},
    "suggestionDeleteHighlightedCard": {"it": "Premi INVIO, o tieni premuto CANCELLA per eliminare la carta selezionata", "en":"Press ENTER, or hold DELETE to delete the selected card", "de": "Drücken Sie EINGABE oder halten Sie DELETE gedrückt, um die ausgewählte Karte zu löschen"},
    "suggestionMoveMode": {"it": "Clicca sulla posizione desiderata per spostare la carta selezionata", "en":"Click on the desired position to move the selected card", "de": "Klicken Sie auf die gewünschte Position, um die ausgewählte Karte zu verschieben"},
    "addToHomeworks": {"it": "Aggiungi ai compiti", "en":"Add to homeworks", "de": "Zu den Hausaufgaben hinzufügen"},
    "homeworks": {"it": "Compiti", "en":"Homeworks", "de": "Hausaufgaben"},
    "noHomeworksLeft": {"it": "Nessun compito rimasto", "en":"No homeworks left", "de": "Keine Hausaufgaben mehr"},
    "correctWellDone": {"it": "Corretto! Ben fatto!", "en":"Correct! Well done!", "de": "Richtig! Gut gemacht!"},
    "reorderTheCards": {"it": "Riordina le carte", "en":"Reorder the cards", "de": "Sortieren Sie die Karten neu"},
    "continue": {"it": "Continua", "en":"Continue", "de": "Fortsetzen"},
    "guessMissingCards": {"it": "Indovina le carte mancanti", "en":"Guess the missing cards", "de": "Erraten Sie die fehlenden Karten"},
    "clickOnTheMissingCardAndSelectTheRightOneFromPossibleAnswers": {"it": "Clicca sulla carta mancante e seleziona quella giusta tra le risposte possibili", "en":"Click on the missing card and select the right one from possible answers", "de": "Klicken Sie auf die fehlende Karte und wählen Sie die richtige aus den möglichen Antworten"},
    "whatTextCorrespondToTheGivenImage": {"it": "A quale testo corrisponde l'immagine?", "en":"What text correspond to the given image?", "de": "Welcher Text entspricht dem gegebenen Bild?"},
    "whatImageCorrespondToTheGivenText": {"it": "A quale immagine corrisponde il testo?", "en":"What image correspond to the given text?", "de": "Welches Bild entspricht dem gegebenen Text?"},
    "autoQuiz": {"it": "Auto-quiz", "en":"Auto-quiz", "de": "Auto-quiz"},
    "congrats": {"it": "Congratulazioni!", "en":"Congrats!", "de": "Herzlichen Glückwunsch!"},
    "youCompletedTheQuiz": {"it": "Hai completato il quiz!", "en":"You completed the quiz!", "de": "Sie haben das Quiz abgeschlossen!"},
    "retakeTheQuiz": {"it": "Rifai il quiz", "en":"Retake the quiz", "de": "Machen Sie das Quiz erneut"},
    "autoQuizTooFewCards": {"it": "Aggiungi almeno 4 carte nel ramo corrente per attivare il quiz!", "en":"Add at least 4 cards in the current branch to activate the quiz!", "de": "Fügen Sie mindestens 4 Karten im aktuellen Zweig hinzu, um das Quiz zu aktivieren!"},
    "bigImageDescription": {"it": "Componi la descrizione dell'immagine", "en":"Compose the image description", "de": "Komponieren Sie die Bildbeschreibung"},
    "bigImageQuizAlert": {"it": "Clicca prima su 1 carta contenente altre carte", "en":"Click first on 1 card containing other cards", "de": "Klicken Sie zuerst auf 1 Karte, die andere Karten enthält"},
    "toBecameAPremiumUserOpenMenuAndClickThePremiumButton": {"it": "Per diventare un utente premium, apri il menu, entra in modalità admin e clicca sul pulsante Premium.", "en":"To became a premium user, open the menu, enter in admin mode and click on the Premium button.", "de": "Um ein Premium-Benutzer zu werden, öffnen Sie das Menü, geben Sie den Admin-Modus ein und klicken Sie auf die Premium-Schaltfläche."},
    "typeTheCardNameInTheBelowEmptyCard": {"it": "Scrivi una parola nella carta vuota sottostante.", "en":"Type a word in the empty card below.", "de": "Geben Sie ein Wort in die leere Karte unten ein."},
    "allCapitalLetters": {"it": "Tutte maiuscole", "en":"All capital letters", "de": "Alle Großbuchstaben"},
    "allSmallLetters": {"it": "Tutte minuscole", "en":"All small letters", "de": "Alle Kleinbuchstaben"},
    "hideText": {"it": "Nascondi testo", "en":"Hide text", "de": "Text ausblenden"},
    "normalText": {"it": "Testo normale", "en":"Normal text", "de": "Normaler Text"},
    "biggerCards": {"it": "Carte più grandi", "en":"Bigger cards", "de": "Größere Karten"},
    "smallerCards": {"it": "Carte più piccole", "en":"Smaller cards", "de": "Kleinere Karten"},
    "options": {"it": "Opzioni", "en":"Options", "de": "Optionen"},
    "sentence": {"it": "Frase", "en":"Sentence", "de": "Satz"},
    "toBuildASentenceClickOnTheCardsInOrder": {"it": "Per costruire una frase qui, clicca sulle carte nell'ordine desiderato.", "en":"To build a sentence here, click on the cards in the desired order.", "de": "Um hier einen Satz zu bilden, klicken Sie in der gewünschten Reihenfolge auf die Karten."},
    "toCreateNewCardsOpenTheLeftLateralMenuAndActivateAdminMode": {"it": "Per creare nuove carte, apri il menu laterale sinistro e attiva la modalità admin.", "en":"To create new cards, open the left lateral menu and activate admin mode.", "de": "Um neue Karten zu erstellen, öffnen Sie das linke Seitenmenü und aktivieren Sie den Admin-Modus."},
    "nowClickOnTheAboveNewCardOrBelowBranchButtonToAddCardsToStartCreatingCards": {"it": "Clicca su 'NUOVA CARTA' sopra o sul pulsante di scelta in basso per aggiungere nuove carte.", "en":"Now click on 'NEW CARD' above or on the branch button below to start creating cards.", "de": "Klicken Sie jetzt auf 'NEUE KARTE' oben oder auf die Zweig-Schaltfläche unten, um mit der Erstellung von Karten zu beginnen."},
    "toDeactivateQuickEditModeClickAgainOnNewCard": {"it": "Per uscire dalla modifica rapida, clicca di nuovo su 'NUOVA CARTA'. Per spostare l'inserimento, clicca sulle altre carte. Per selezionarle, tieni premuto.", "en":"To deactivate quick edit mode, click again on 'NEW CARD'. To move it, click on the other cards. Hold to select them.", "de": "Um den Schnellbearbeitungsmodus zu deaktivieren, klicken Sie erneut auf 'NEUE KARTE'. Um es zu verschieben, klicken Sie auf die anderen Karten. Halten Sie zum Auswählen gedrückt."},
    "removeSelectedCard": {"it": "Rimuovi", "en":"Remove", "de": "Entfernen"},
    "followTheSameOrderOfTheCardsAsTheyAppearFromLeftToRight": {"it": "Segui lo stesso ordine delle carte come sono state create, da sinistra a destra.", "en":"Follow the same order of the cards as they appear, from left to right.", "de": "Folgen Sie der gleichen Reihenfolge der Karten, wie sie von links nach rechts erscheinen."},
    "fbBrowserUnsupported": {
        "en": "Facebook's browser is not supported. Click on the 3 dots in the top-right corner and select 'Open in Chrome' (or other browsers).",
        "it": "Il browser di Facebook non è supportato. Clicca sui 3 puntini nell'angolo in alto a destra e seleziona 'Apri in Chrome' (o altri browser).",
        "de": "Der Browser von Facebook wird nicht unterstützt. Klicken Sie auf die 3 Punkte in der oberen rechten Ecke und wählen Sie 'In Chrome öffnen' (oder andere Browser)."
        
      },
      "inAppBrowserUnsupported": {
        "en": "Your in-app browser is not supported. Please use Chrome, Safari, or any other modern browser.",
        "it": "Il tuo browser in-app non è supportato. Si prega di utilizzare Chrome, Safari o qualsiasi altro browser moderno.",
        "de": "Ihr In-App-Browser wird nicht unterstützt. Bitte verwenden Sie Chrome, Safari oder einen anderen modernen Browser."
      },
      "downloadApp": {
        "en": "Download the app from the Play Store.",
        "it": "Scarica l'app dal Play Store.",
        "de": "Laden Sie die App aus dem Play Store herunter."
      },
      "copyLinkBrowser": {
        "en": "Copy the link below and open it in your preferred browser.",
        "it": "Copia il link sottostante e aprilo nel tuo browser preferito.",
        "de": "Kopieren Sie den unten stehenden Link und öffnen Sie ihn in Ihrem bevorzugten Browser."
      },
      "openInPlayStore": {
        "en": "Open in Play Store",
        "it": "Apri nel Play Store",
        "de": "Im Play Store öffnen"
      },
      "copyLinkButton": {
        "en": "Copy Link",
        "it": "Copia il link",
        "de": "Link kopieren"
      },
      "anotherSolution": {
        "en": "Give me another solution",
        "it": "Dammi un'altra soluzione",
        "de": "Gib mir eine andere Lösung"
      },
      "urlCopied": {
        "en": "URL Copied!",
        "it": "URL Copiato!",
        "de": "URL kopiert!"
      },
      "pasteInBrowser": {
        "en": "Paste it in your preferred browser to continue.",
        "it": "Incollalo nel tuo browser preferito per continuare.",
        "de": "Fügen Sie es in Ihren bevorzugten Browser ein, um fortzufahren."
      },
      "connectionProblemsTryLater": {
        "en": "Connection problems. Try again later.",
        "it": "Problemi di connessione. Riprova più tardi.",
        "de": "Verbindungsprobleme. Versuchen Sie es später erneut."
      },
      "doYouWantToChangeImageChooseAnotherOneBelow": {
        "en": "Do you want to change the image? Choose another one below.",
        "it": "Vuoi cambiare l'immagine? Scegli un'altra qui sotto.",
        "de": "Möchten Sie das Bild ändern? Wählen Sie unten ein anderes aus."
      },
      "done":{
        "it":"Fatto!",
        "en":"Done!",
        "de":"Erledigt!"
    },
    "otherOptions": {
        "it": "Altre opzioni",
        "en": "Other options",
        "de": "Andere Optionen"
    },
    "noUserPleaseLogin": {
        "it": "Nessun utente! Accedi.",
        "en": "No user! Login.",
        "de": "Kein Benutzer! Anmelden."
    },
    "clickHereToLogin": {
        "it": "Clicca qui per accedere",
        "en": "Click here to login",
        "de": "Klicken Sie hier, um sich anzumelden"
    },
    "loginOnly": {
        "it": "Accedi",
        "en": "Login",
        "de": "Anmelden"
    },
    "clickToEditCellDoubleClickOrLongPressToSelect": {
        "it": "Clicca su una cella, scrivi e premi invio per creare una carta. Doppio click o tieni premuto per selezionare.",
        "en": "Click on a cell, write and press enter to create a card. Double click or long press to select.",
        "de": "Klicken Sie auf eine Zelle, schreiben Sie und drücken Sie die Eingabetaste, um eine Karte zu erstellen. Doppelklicken oder lange drücken, um auszuwählen."

    },
    "gridMode": {
        "it": "Mod. Griglia",
        "en": "Grid mode",
        "de": "Rastermodus"
    },
    "activateGridModeToInserNewCards": {
        "it": "Attiva la modalità griglia per inserire nuove carte",
        "en": "Activate grid mode to insert new cards",
        "de": "Aktivieren Sie den Rastermodus, um neue Karten einzufügen"
    },
    "clickHereToAddACard": {
        "it": "Aggiungi una carta",
        "en": "Add a card",
        "de": "Karte hinzufügen"
    },
    "deactivateGridMode": {
        "it": "Disattiva griglia",
        "en": "Deactivate grid",
        "de": "Raster deaktivieren"
    },
    "fontSize": {
        "it": "Dimensione testo",
        "en": "Font size",
        "de": "Schriftgröße"
    },
    "cardSize": {
        "it": "Dimensione carta",
        "en": "Card size",
        "de": "Kartengröße"
    },
    "setPropertiesAsDefaultForAllBranches": {
        "it": "Imposta proprietà come predefinite per tutti i rami",
        "en": "Set properties as default for all branches",
        "de": "Eigenschaften als Standard für alle Zweige festlegen"
    },
    "newLayout": {
        "it": "Nuovo layout",
        "en": "New layout",
        "de": "Neues Layout"
    },
    "storeLayout": {
        "it": "Salva",
        "en": "Store",
        "de": "Speichern"
    },
    "pleaseEnterANameBeforeSaving":
    {
        "it": "Inserisci un nome prima di salvare",
        "en": "Please enter a name before saving",
        "de": "Bitte geben Sie einen Namen ein, bevor Sie speichern"
    },
    
    "storedLayoutsList": {
        "it": "Layout salvati",
        "en": "Stored layouts",
        "de": "Gespeicherte Layouts"
    },
    "load":{ "it": "Carica", "en": "Load", "de": "Laden"},
    "removeSelectedItems": { "it": "Rimuovi selezionati", "en": "Remove selected", "de": "Ausgewählte entfernen"},
    "exportPDF": { "it": "Esporta PDF", "en": "Export PDF", "de": "PDF exportieren"},
    "showPage": { "it": "Mostra pagina", "en": "Show page", "de": "Seite anzeigen"},
    "reposition": { "it": "Riposiziona (Opzioni)", "en": "Reposition (Options)", "de": "Neu positionieren (Optionen)"},
    "atTopLeftCorner": { "it": "In alto a sinistra", "en": "At top left corner", "de": "Oben links"},
    "atLastStoredPositions": { "it": "Ripristina ultimo salvataggio", "en": "Restore last stored positions", "de": "Letzte gespeicherte Positionen wiederherstellen"},
    "inAGridLikePositions": { "it": "In una griglia", "en": "In a grid", "de": "In einem Raster"},
    "newLine": { "it": "A capo", "en": "New line", "de": "Neue Zeile"},
    "removeEntireRows": { "it": "Rimuovi riga", "en": "Remove row", "de": "Zeile entfernen"},
    "exportPDFAlert": {"it": "Entra in modalità artistica per esportare in PDF.", "en": "Enter in artist mode to export in PDF.", "de": "Gehen Sie in den Künstlermodus, um in PDF zu exportieren."},
    
    "chooseImageSource": {"it": "Scegli la fonte dell'immagine", "en": "Choose the image source", "de": "Wählen Sie die Bildquelle"},
    "pleaseNote": {"it": "Nota:", "en": "Please note:", "de": "Bitte beachten:"},
    "autoQuizDescription":{"it": "Riordina, completa e associa le immagini", "en": "Reorder, complete and match the images", "de": "Bilder neu anordnen, vervollständigen und zuordnen"},
    "thanksForChoosingLang": {"it": "Grazie per aver scelto la lingua", "en": "Thanks for choosing the language", "de": "Danke, dass Sie die Sprache gewählt haben"},
    "proceed": {"it": "Procedi", "en": "Proceed", "de": "Weiter"},
    "LPwelcomeMsg":{"it": "L'apprendimento in CAA su misura con DSApp.", "en": "Tailored AAC learning with DSApp.", "de": "Maßgeschneidertes AAC-Lernen mit DSApp."},
    "customizeContentMsg": {
        "it": "Crea contenuti usando la CAA (Comunicazione Alternativa e Aumentativa) risparmiando fino all'80% del tuo tempo.",
        "en": "Create content using AAC (Alternative and Augmentative Communication) saving up to 80% of your time.",
        "de": "Erstellen Sie Inhalte mit AAC (Alternative und Augmentative Kommunikation) und sparen Sie bis zu 80% Ihrer Zeit."

    },
    "optimizeTimeMsg": {
        "it": "Ottimizza il tuo tempo",
        "en": "Optimize your time",
        "de": "Optimieren Sie Ihre Zeit"
    },
    "generatManyExercisesFast":{
        "it": "Creare esercizi in pochi secondi.",
        "en": "Generate exercises in seconds.",
        "de": "Erstellen Sie Übungen in Sekunden."
    },
    "convertTextToCardsWithCopyPaste": {
        "it": "Convertire molto testo in carte.",
        "en": "Convert a lot of text into cards.",
        "de": "Konvertieren Sie viel Text in Karten."
    },
    "readyToUseMaterial": {
        "it": "Storie pronte all'uso.",
        "en": "Ready-to-use stories.",
        "de": "Fertige Geschichten."
    },
    "whatDoYouNeed": {
        "it": "Cosa ti serve?",
        "en": "What do you need?",
        "de": "Was brauchst du?"
    },
    "reducePrepTimeMsg": {
        "it": "Riduci il tempo di preparazione delle lezioni fino all'80% con i nostri strumenti di generazione automatica dei contenuti.",
        "en": "Reduce lesson preparation time by up to 80% with our automated content generation tools.",
        "de": "Reduzieren Sie die Vorbereitungszeit für den Unterricht um bis zu 80% mit unseren Tools zur automatischen Inhaltsgenerierung."
    },
    "stayUpdated":{
        "it": "Resta aggiornato",
        "en": "Stay updated",
        "de": "Bleiben Sie auf dem Laufenden"
    },
    "doYouWantToStayInformedPerEmail":{
        "it": "Vuoi rimanere informato via email?",
        "en": "Do you want to stay informed via email?",
        "de": "Möchten Sie per E-Mail informiert bleiben?"
    },
    "noThanks":{
        "it": "No, grazie",
        "en": "No, thanks",
        "de": "Nein, danke"

    },
    "customizeCardsAndExportToPDF":{
        "it": "Personalizzare le carte ed esportare in PDF.",
        "en": "Customize cards and export to PDF.",
        "de": "Karten anpassen und in PDF exportieren."

    },
    "accessibleEverywhereMsg": {
        "it": "Accessibile ovunque",
        "en": "Accessible everywhere",
        "de": "Überall zugänglich"
    },
    "accessibleOnDevicesMsg": {
        "it": "Accessibile su un'ampia varietà di dispositivi quali PC Desktop, portatili, smartphones e tablets.",
        "en": "Accessible on a wide variety of devices including desktop PCs, laptops, smartphones, and tablets.",
        "de": "Zugänglich auf einer breiten Palette von Geräten, einschließlich Desktop-PCs, Laptops, Smartphones und Tablets."
    },
    "exportPDFShareMsg": {
        "it": "Esporta in PDF o condividi il tuo materiale tramite file o link.",
        "en": "Export to PDF or share your materials via file or link.",
        "de": "Exportieren Sie in PDF oder teilen Sie Ihre Materialien über Datei oder Link."
    },
    "tailoredLearningMsg": {
        "it": "Apprendimento su misura",
        "en": "Tailored learning",
        "de": "Maßgeschneidertes Lernen"
    },
    "customizeMaterialsMsg": {
        "it": "Personalizza i materiali per adattarli alle esigenze uniche di ogni studente.",
        "en": "Customize materials to suit the unique needs of each student.",
        "de": "Passen Sie Materialien an die einzigartigen Bedürfnisse jedes Schülers an."
    },
    "startFreeMsg": {
        "it": "Inizia gratis - Annulla quando vuoi!",
        "en": "Start for free - Cancel anytime!",
        "de": "Kostenlos starten - Jederzeit kündbar!"
    },
    "pricing": {
        "it": "Pricing",
        "en": "Pricing",
        "de": "Pricing"
    },
    "selectThePlanThatSRightForYou": {
        "it": "Seleziona il piano che fa per te",
        "en": "Select the plan that's right for you",
        "de": "Wählen Sie den Plan, der zu Ihnen passt"
    },
    "allPlansComeWith": {
        "it": "Tutti i piani includono",
        "en": "All plans come with ",
        "de": "Alle Pläne enthalten "
    },

    "aOneMonthFreeTrial": {
        "it": "Un mese di prova gratuita",
        "en": "A one-month free trial",
        "de": "Ein einmonatiger kostenloser Test"
    },
    "startPayingFromTheSecondMonth":{
        "it": "Inizia a pagare dal secondo mese",
        "en": "Start paying from the second month",
        "de": "Zahlen Sie ab dem zweiten Monat"
    },
    "cancelAnytime":{
        "it": "Annulla quando vuoi",
        "en": "Cancel anytime",
        "de": "Jederzeit kündbar"
    },
    "enjoyFullAccessWithMonthlyFlexibility": {
        "it": "Goditi l'accesso completo con flessibilità mensile.",
        "en": "Enjoy full access with monthly flexibility.",
        "de": "Genießen Sie vollen Zugriff mit monatlicher Flexibilität."
    },
    "payAnnually": {
        "it": "Paga annualmente",
        "en": "Pay annually",
        "de": "Jährlich zahlen"
    },
    "save": {
        "it": "Risparmia il",
        "en": "Save the",
        "de": "Sparen"
    },
    "comparedToTheMonthlyPlanThatSTwoMonthsFreeEveryYear": {
        "it": "rispetto al piano mensile. Sono due mesi gratis ogni anno!",
        "en": "compared to the monthly plan. That's two months free every year!",
        "de": "im Vergleich zum monatlichen Plan. Das sind zwei Monate kostenlos pro Jahr!"
    },
    "useThePromotionalCode": {
        "it": "Al momento del pagamento, usa il codice promozionale",
        "en": "At checkout, use the promotional code",
        "de": "Verwenden Sie an der Kasse den Aktionscode"
    },
    "forAFurther": {
        "it": "per un ulteriore",
        "en": "for a further",
        "de": "für eine weitere"
    },
    "lifetimeDiscount": {
        "it": "di sconto per sempre",
        "en": "lifetime discount",
        "de": "lebenslanger Rabatt"
    },
    "validForTheFirst1000SubscribersOnly": {
        "it": "Valido solo per i primi 1000 abbonati.",
        "en": "Valid for the first 1000 subscribers only.",
        "de": "Gültig nur für die ersten 1000 Abonnenten."
    },
    "subscribe": {
        "it": "Scegli",
        "en": "Subscribe",
        "de": "Abonnieren"
    },
    
    
};

/*





    isposte di primo livello. Il computer genera automaticamente un’immagine e utilizza le carte di primo livello per aggiungere domande e le sotto-carte come risposte."
    
    "Rispondi alle domande sull'immagine generata. In ogni round, viene mostrata un'immagine, che risulta essere una combinazione  e vengono poste domande relative ad essa. Le risposte sono rappresentate da immagini. I bambini selezionano l'immagine di risposta corretta per avanzare nel gioco.
    ",
    "en":"The user has to choose the card corresponding to the question asked.", "de": "Der Benutzer muss die Karte auswählen, die der gestellten Frage entspricht."},





    Attenzione: cambiare nome, immagini e posizione delle carte coinvolte influirà automaticamente sull'attività selezionata
*/

export const _urls: { [id:string] : { [id:string] : string }}  = {
    "feedbackForm": {"it": "https://docs.google.com/forms/d/e/1FAIpQLSd1IiWXYogptbi9nFt3FvdpWSulSOO3vnmJxR01dMeY2CivFQ/viewform?usp=sf_link",
                     "en": "https://docs.google.com/forms/d/e/1FAIpQLSfVUU28Ev2U1tR63-Up9buOSdb3eKx0x4-X-NfFf9lNYlQD4Q/viewform?usp=sf_link",
                     "de": "https://docs.google.com/forms/d/e/1FAIpQLSefMXOS2PZVYZQBjs_Ap5zpJRtPb9q26OYsP2N4AGQwJUYVTg/viewform?usp=sf_link"},
    "loginProblemForm": {"it": "https://docs.google.com/forms/d/e/1FAIpQLSfyClW7VAQWlcxMj-QrnisdUf5PR6UOvgD6dD4EyMtbwuLHqA/viewform?usp=sf_link",
                         "en": "https://docs.google.com/forms/d/e/1FAIpQLSfyClW7VAQWlcxMj-QrnisdUf5PR6UOvgD6dD4EyMtbwuLHqA/viewform?usp=sf_link",
                         "de": "https://docs.google.com/forms/d/e/1FAIpQLSfyClW7VAQWlcxMj-QrnisdUf5PR6UOvgD6dD4EyMtbwuLHqA/viewform?usp=sf_link"},
    "ads": {"it": "https://docs.google.com/forms/d/e/1FAIpQLSfgBqofZBjlBOBRvXlgUWGblBmYBDHDP9vrkb5rae4CQv7sAw/viewform?usp=sf_link",
            "en": "https://docs.google.com/forms/d/e/1FAIpQLSc0UlNaRTBzoCHg8FL6dqlOKds9vrr6UqxW6JXgkQ5X5dbFcQ/viewform?usp=sf_link",
            "de": "https://docs.google.com/forms/d/e/1FAIpQLSceDe-tEVBaM9qbXvJ1LF6ViOFh2k5kc38W75-xcmExcdKIFQ/viewform?usp=sf_link"
            }

}


export const _helpCards = [
    
    // DO NOT ADD ANY &ab_channel=ClipTalk SUFFIX TO THE URLS
    {
        "it": {
            title: "Inserimento Rapido",
            description: "Inserisci nuove carte in tempo reale",
            videoURL: "https://www.youtube.com/watch?v=sxuzZnAioAA"
        }
    },
    {
        "it":{
            title: "Crea agende visive",
            description: "Crea agende visive con le tue carte",
            videoURL: "https://www.youtube.com/watch?v=4ND0xqNVy3c"

        }
    },
    {
        "it":{
            title:"Le attività",
            description:"Crea attività di comprensione visiva",
            videoURL: "https://www.youtube.com/watch?v=z9XbzHYDwUw"

        }
    },
    {
        "it": {
            title: "Racconta una storia",
            description: "Racconta una storia creando una sequenza di carte",
            videoURL: "https://www.youtube.com/watch?v=vUUiozZi8AU"
        },
    },
    {
        "it": {
            title: "Condividi le PECS",
            description: "Condividi le PECS in pochi click, senza limiti",
            videoURL: "https://www.youtube.com/watch?v=Vn3Q7Wci9yM",
        }
    },
    {
        "it": {
            title: "Crea le tue liste",
            description: "Crea le tue liste di parole, una per riga",
            videoURL: "https://www.youtube.com/watch?v=9jRGDp_HEkg",
        }
    },
    {
        "it": {
            title: "L'assistente Smart",
            description: "L'assistente Smart ti aiuta a creare le tue carte in automatico",
            videoURL: "https://www.youtube.com/watch?v=AcWMHwveG_w",
        }
    },
        
   
    // Intro:
    {
    "en": {
        title: "Intro",
        description: "Short functional basic overview introduction (no tutorial)",
        videoURL: _dict.dsappVideoURL["en"]
    },
    "it": {
        title: "Intro",
        description: "Promo",
        videoURL: _dict.dsappVideoURL["it"]
    },
    "de": {
        title: "Intro",
        description: "Kurze Grundübersicht (kein Tutorial)",
        videoURL: _dict.dsappVideoURL["de"]
    }
}];

export const _faqData = [
    {
        "it": {
            title: "Quanto costa e quali sono i vantaggi di un account premium?",
            description: "L'abbonamento Premium ha un costo di 5,90 Euro al mese. A differenza della versione gratuita, con l'account Premium permette l'utilizzo di un numero illimitato di carte, l'accesso a tutte le attività interattive, un migliore abbinamento automatico tra testo e pittogrammi, la priorità nelle richieste di inserimento di nuovi contenuti, la sincronizzazione delle carte su più dispositivi e l'assenza di interruzioni pubblicitarie."
        },
        "en": {
            title: "What is the cost and what are the benefits of a premium account?",
            description: "The Premium subscription costs 5.90 Euros per month. Unlike the free version, the Premium account offers unlimited use of cards, access to all interactive activities, improved automatic matching between text and pictograms, priority in content insertion requests, card synchronization across multiple devices, and an ad-free experience."
        },
        "de": {
            title: "Was kostet ein Premium-Konto und welche Vorteile bietet es?",
            description: "Das Premium-Abonnement kostet 5,90 Euro pro Monat. Im Gegensatz zur kostenlosen Version bietet das Premium-Konto die unbegrenzte Nutzung von Karten, Zugang zu allen interaktiven Aktivitäten, eine verbesserte automatische Zuordnung zwischen Text und Piktogrammen, Priorität bei der Einfügung von neuen Inhalten, Synchronisierung der Karten auf mehreren Geräten und eine werbefreie Nutzung."
        }
    },
    {
        "it": {
            title: "Come posso disdire l'abbonamento?",
            description: "Per disdire l'abbonamento esistono diverse opzioni. Puoi inviare un'email all'indirizzo ingap.dev@gmail.com, contattare la pagina Facebook, compilare una richiesta nel modulo di contatto presente nel menu laterale dell'app, o compilare il feedback nella pagina dedicata."
        },
        "en": {
            title: "How can I cancel my subscription?",
            description: "To cancel your subscription, you have several options. You can send an email to ingap.dev@gmail.com, contact us through the Facebook page, fill out a request in the contact form located in the app's side menu, or submit feedback on the dedicated page."
        },
        "de": {
            title: "Wie kann ich mein Abonnement kündigen?",
            description: "Um Ihr Abonnement zu kündigen, stehen Ihnen mehrere Optionen zur Verfügung. Sie können eine E-Mail an ingap.dev@gmail.com senden, uns über die Facebook-Seite kontaktieren, eine Anfrage im Kontaktformular im seitlichen Menü der App ausfüllen oder Feedback auf der dedizierten Seite einreichen."
        }
    },
    {
        "it": {
            title: "Cosa comporta la disdetta?",
            description: "Una volta inviata la richiesta, l'annullamento dell'abbonamento avrà effetto immediato. Qualunque costo eventualmente addebitato dopo la richiesta di annullamento o l'eventuale periodo rimanente non utilizzato verrà interamente rimborsato entro pochi giorni​​."
        },
        "en": {
            title: "What happens upon cancellation?",
            description: "Once your cancellation request is submitted, the subscription will be immediately terminated. Any charges incurred after the cancellation request or any remaining unused period will be fully refunded within a few days."
        },
        "de": {
            title: "Was passiert nach einer Kündigung?",
            description: "Sobald Ihr Kündigungsantrag eingereicht wird, wird das Abonnement sofort beendet. Alle nach der Kündigungsanfrage entstandenen Kosten oder ein verbleibender ungenutzter Zeitraum werden innerhalb weniger Tage vollständig erstattet."
        }
    }
]
;
