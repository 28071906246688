import { defineCustomElements } from "@ionic/pwa-elements/loader";
import React, { Suspense, lazy, memo } from "react";
import { createRoot } from "react-dom/client";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { IonSpinner } from "@ionic/react";
import { _debugMode, _dict } from "./components/constants";
const App = lazy(() => import("./App"));
const MemoizedApp = memo(App);



const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  
    <Suspense fallback={<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <IonSpinner />
        </div>}>
      <MemoizedApp />
    </Suspense>
  
);
defineCustomElements(window);
serviceWorkerRegistration.register();


if (navigator.storage && navigator.storage.persist) {
  navigator.storage.persist().then(granted => {
    if (granted) {
      if(_debugMode){console.log('Storage will not be cleared except by explicit user action');}
    } else {
      if(_debugMode){console.warn('Storage may be cleared by the browser under storage pressure.');}
    }
  });
}